<template>
  
  <div class="m-3">

    
  </div>
</template>

<script>
import axios from "axios"

export default {
  data(){
    return {
      user: undefined
    }
  },
  mounted(){
    axios( '/api/user').then((response)=>{
      this.user = response.data
      this.$router.push('/person/'+this.user.username)
    })
  }
}
</script>