<template>
  <div id="app">

    <b-navbar variant="dark" type="dark">
      <b-navbar-brand href="#">
        <!-- <img src="https://placekitten.com/g/30/30" class="d-inline-block align-top" alt="Kitten"> -->
        <b-button v-b-toggle.sidebar-1 class="mr-2"><b-icon icon="list" ></b-icon></b-button>
        Housework
      </b-navbar-brand>
    </b-navbar>

    <b-sidebar
      id="sidebar-1"
      title="Housework"
      backdrop
      shadow
    >
      <b-list-group>
        <b-list-group-item><router-link to="/report">Summary</router-link></b-list-group-item>
        <b-list-group-item><router-link to="/expectations">Expectations</router-link></b-list-group-item>
        <b-list-group-item v-if="user?.authorities?.includes('ROLE_ADMIN')"><router-link to="/admin">Admin</router-link></b-list-group-item>
        <b-list-group-item v-for="person in people" :key="person.id"><router-link :to="'/person/'+person.id">{{ person.name }}</router-link></b-list-group-item>
      </b-list-group>
    </b-sidebar>

    <!--<img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>

      <div>
        <b-table striped hover :items="items" small="true"></b-table>
      </div>-->

      <router-view />
  </div>
</template>

<script>
import axios from "axios"
//import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'HouseWork',
  mounted(){
    axios( '/api/user').then((response)=>{
      this.user = response.data
    })
    axios("/api/people-names").then((response)=>{
      this.people = response.data
    })
  },
  data(){
    return {
      user: undefined,
      people: []
    }
  },
  components: {
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  margin: 0px;
}
</style>
