<template>
  
    <div class="p-3">
      <div class="accordion" role="tablist" >
        <b-card no-body class="mb-1" v-for="expectation in expectations.expectations" :key="expectation.id">
            <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle="[expectation.id]" variant="info">{{ expectation.title }}</b-button>
            </b-card-header>
            <b-collapse :id="expectation.id" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                <b-card-text><div v-html="expectation.descriptionHtml"></div></b-card-text>
                <div v-if="user?.authorities?.includes('ROLE_ADMIN')">
                    <b-button @click="edit(expectation.id)">Edit</b-button>
                    <b-button @click="deleteExpectation(expectation.id)" variant="danger" class="ml-3">Delete</b-button>
                </div>
                </b-card-body>
            </b-collapse>
        </b-card>
      </div>
      <div v-if="user?.authorities?.includes('ROLE_ADMIN')">
        
        <b-input-group class="mt-3">
            <b-form-input v-model="newTask.title" placeholder="Task Name"></b-form-input>
        </b-input-group>
        <b-form-textarea
            id="textarea"
            v-model="newTask.description"
            placeholder="Enter something..."
            rows="3"
            max-rows="6"
            ></b-form-textarea>
        <div v-if="editing"><b-button variant="primary" @click="update">Update</b-button><b-button variant="secondary" @click="reset" class="ml-3">New</b-button></div>
        <b-button variant="primary" v-else @click="add">Add</b-button>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios"
  
  export default {
    data(){
      return {
        user: undefined,
        expectations: {expectations:[]},
        newTask: {
            id: undefined,
            title: undefined,
            description: undefined
        }
      }
    },
    mounted(){
      axios( '/api/user').then((response)=>{
        this.user = response.data
      })
      axios( '/api/expectations').then((response)=>{
        this.expectations = response.data
      })
    },
    computed:{
        editing(){
            return !!this.newTask.id
        }
    },
    methods:{
        reset(){
            this.newTask.id = undefined
            this.newTask.title = undefined
            this.newTask.description = undefined
        },
        add(){
            this.expectations.expectations.push({
                id: String(Math.floor(Math.random()*9007199254740991)),
                title: this.newTask.title,
                descriptionHtml: this.newTask.description
            })
            axios({
                method: 'post',
                url: '/api/expectations',
                data: this.expectations
            }).then((response)=>{
                this.expectations = response.data
            })
        },
        update(){
            this.expectations.expectations.forEach((item)=>{
                if(item.id !== this.newTask.id){
                    return;
                }
                item.title = this.newTask.title
                item.descriptionHtml = this.newTask.description
            })
            this.newTask.id = undefined
            this.newTask.title = undefined
            this.newTask.description = undefined
            axios({
                method: 'post',
                url: '/api/expectations',
                data: this.expectations
            }).then((response)=>{
                this.expectations = response.data
            })
        },
        edit(id){
            this.expectations.expectations.forEach((item)=>{
                if(item.id!==id){
                    return;
                }
                this.newTask.id = id
                this.newTask.title = item.title
                this.newTask.description = item.descriptionHtml
            })
        },
        deleteExpectation(id){
            let r = this.expectations.expectations
            let found = -1
            for(let i=0;i<r.length;i++){
                if(r[i].id === id){
                    found = i;
                    break
                }
            }
            if(found !== -1){
                this.expectations.expectations.splice(found,1)
                axios({
                    method: 'post',
                    url: '/api/expectations',
                    data: this.expectations
                }).then((response)=>{
                    this.expectations = response.data
                })
            }
        }
    }
  }
  </script>