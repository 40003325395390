<template>
    <div  class="m-3">

        <p>All activity measured in minutes</p>

        <b-table striped hover :items="thisWeek.items" :fields="fields" :id="String(thisWeek.renderId)"></b-table>

        <b-card title="Last Week">
            <b-table striped hover :items="lastWeek.items" :fields="fields" :id="String(lastWeek.renderId)"></b-table>
        </b-card>

    </div>
</template>
<script>
import axios from "axios"

function addDerived(person){
  person.meritsCount = 0
  person.demeritsCount = 0

  for(let i=0; i<person?.merits?.entries?.length; i++){
    let day = person?.merits?.entries[i]
    for(let j=0; j<day?.value?.length; j++){
      let entry = day?.value[j]
      let count = entry.number
      if(count>0){
        person.meritsCount += count;
      }else{
        person.demeritsCount -= count;
      }
    }
  }
}

function update(activities,person,data,week){
    let id = person.id

    let setItem = (fn)=>{
        let items = data.items.filter((item)=>{return item.id === id})
        let item
        if(items.length === 0){
            item = {id,name:person.name}
            data.items.push(item);
        }else{
            item = items[0]
        }
        fn(item)

        data.items = [...data.items]

        data.renderId += 1
    }

    axios( '/api/activities/'+id+'/'+week).then((response)=>{
        activities[id] = response.data
        let manualDuration = 0
        let exerciseDuration = 0
        let workDuration = 0
        data.totalDuration[id] = 0
        for(let i=0; i<activities[id].length; i++){
            if(activities[id][i].category === "work"){
                workDuration += activities[id][i].duration
            }else if(activities[id][i].category === "exercise"){
                exerciseDuration += activities[id][i].duration
            }else{
                manualDuration += activities[id][i].duration
            }
        }
        setItem((item)=>{
            item.totalDuration = workDuration + manualDuration +exerciseDuration
            item.workDuration = workDuration
            item.manualDuration = manualDuration
            item.exerciseDuration = exerciseDuration
        })
    })

    axios( '/api/person/'+id+'/'+week).then((response)=>{
        let person = response.data
        addDerived(person)
        setItem((item)=>{
            item.merits = person.meritsCount
            item.demerits = person.demeritsCount
            item.totalMerits = person.meritsCount - person.demeritsCount
        })
    })
}

export default {
    mounted(){
        this.refresh()
    },
    data(){
        return {
            renderId: 0,
            people:[],
            activities:{},
            totalDuration:{},
            summaryFields:[
                "name",
                {key:"totalDuration", label:"Activity", sortable: true},
                {key:"totalMerits",label:"Merits",sortable: true}
            ],
            fields:[
                "name",
                {key:"workDuration", label:"Work/School", sortable: true},
                {key:"exerciseDuration", label:"Exercise", sortable: true},
                {key:"manualDuration", label:"Housework", sortable: true},
                // {key:"autoActivity", label:"Other Activity", sortable: true},
                {key:"totalDuration", label:"Total Activity", sortable: true},
                // {key:"merits",sortable: true},
                // {key:"demerits",sortable: true},
                {key:"totalMerits",label:"Merits",sortable: true}
            ],
            items:[],
            thisWeek:{items:[],totalDuration:{},renderId: 0},
            lastWeek:{items:[],totalDuration:{},renderId: 0}
        }
    },
    methods:{
        refresh(){
            axios("/api/people-names").then((response)=>{
                this.people = response.data
                for(let i=0;i<this.people.length;i++){
                    update(this.activities,this.people[i],this.thisWeek,0)
                    update(this.activities,this.people[i],this.lastWeek,1)
                }
            })
        }
    }
}
</script>
