<template>
  
  <div class="m-3" :id="renderId">

    <b-card class="mb-3" :title="person.name">
      <!-- <b-card-text> -->
        <b-container>
          <b-row>
            <b-col cols="6" class="text-right">Merits:</b-col>
            <b-col>{{ person.meritsCount }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="6" class="text-right">De-Merits:</b-col>
            <b-col>{{ person.demeritsCount }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="6" class="text-right">Total Lifetime Merits:</b-col>
            <b-col>{{ person.lifetimeMerits }}</b-col>
          </b-row>
        </b-container>
      <!-- </b-card-text> -->
    </b-card>

    <div v-if="person.meritsCount>0 || person.demeritsCount>0">
      <b-list-group>
        <b-list-group-item class="d-flex align-items-center"  v-for="entry in person.meritHistory" :key="entry.id">
          <!-- <b-avatar class="mr-3"></b-avatar> -->
          <b-icon :icon="entry.icon" class="mr-3" :variant="entry.variant"/>
          <span class="mr-auto">{{entry.time}} {{entry.description}}</span>
          <b-badge :variant="entry.variant"><span v-if="entry.number>0">+</span>{{ entry.number }}</b-badge>
        </b-list-group-item>
      </b-list-group>

    </div>

    <b-card no-body>
      <template #header>
        <h4 class="mb-0">Current Tasks</h4>
      </template>

      <b-card-body v-for="taskDay in tasks" :key="taskDay.day">
        <b-card-title>{{taskDay.dayName}}</b-card-title>
        <b-card-sub-title class="mb-2">{{ taskDay.date }}</b-card-sub-title>
        <b-card-text>
          <div v-for="chore in taskDay.tasks" :key="taskDay.day+'-'+chore.id">
            <b-form-checkbox
              :id="'checkbox-'+chore.id+'-'+chore.time"
              v-model="chore.done"
              :name="'checkbox-'+chore.id+'-'+chore.time"
              :value="true"
              :unchecked-value="false"
              :disabled="disabled"
              @input="taskCompleted(chore.id,chore.time,$event)"
            >
            {{chore.formattedTime}} - {{chore.name}} ({{ chore.duration }} minutes)
            </b-form-checkbox>
          </div>
        </b-card-text>
      </b-card-body>

    </b-card>

    <!-- <b-card no-body>
      <template #header>
        <h4 class="mb-0">Activities</h4>
      </template>
      <b-card-text>
      </b-card-text>
    </b-card> -->
    
  </div>
</template>

<script>
import axios from "axios"

const DAYS = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]

function formatDayDate(time){
  let date = new Date(time);
  return DAYS[date.getDay()]+" "+date.toLocaleDateString()
}

function formatDay(time){
  let date = new Date(time);
  return DAYS[date.getDay()]
}

function formatDate(time){
  let date = new Date(time);
  return date.toLocaleDateString()
}

function doubleDigits(num){
  return num<10 ? "0"+num : String(num)
}

function formatTime(time){
  let date = new Date(time);
  return doubleDigits(date.getHours())+":"+doubleDigits(date.getMinutes())
}

function addDerived(person){
  person.meritsCount = 0;
  person.demeritsCount = 0;
  person.meritHistory = []

  for(let i=0; i<person?.merits?.entries?.length; i++){
    let day = person?.merits?.entries[i]
    for(let j=0; j<day?.value?.length; j++){
      let entry = day?.value[j]

      person.meritHistory.push({
        id: person.meritHistory.length,
        number: entry.number, 
        description: entry.description, 
        time: formatDayDate(day.time), 
        variant: entry.number > 0 ? "success" : "danger",
        icon: entry.number > 0 ? "trophy-fill" : "exclamation-circle-fill"
      })

      let count = entry.number
      if(count>0){
        person.meritsCount += count;
      }else{
        person.demeritsCount -= count;
      }
    }
  }
}

function addTaskDerived(tasks,completedTasks){
  for(let i=0; i<tasks.length; i++){
    tasks[i].dayName = formatDay(tasks[i].day);
    tasks[i].date = formatDate(tasks[i].day);
    for(let j=0;j<tasks[i].tasks.length;j++){
      tasks[i].tasks[j].done = completedTasks.includes(tasks[i].tasks[j].id+":"+tasks[i].tasks[j].time)
      tasks[i].tasks[j].formattedTime = formatTime(tasks[i].tasks[j].time)
    }
  }
}

  export default {
    setup(){
    },
    created() {
      this.$watch(
        () => this.$route.params,
        () => {
          this.refresh();
        }
      )
    },
    mounted(){
      axios( '/api/user').then((response)=>{
        this.user = response.data
      }).then(()=>{
        this.refresh()
      })
    },
    data() {
      return {
        renderId: 0,
        person: {
          name:"",
          merits: {"entries":[]},
          lifetimeMerits: 0,
          image: "https://placekitten.com/200/200"
        },
        tasks:{},
        disabled: true,
        user: undefined
      }
    },
    methods:{
      taskCompleted(id,time,completed){
        let personId = this.$route.params.id
        let taskId = id+":"+time
        // let completed = value.charAt(0) == 'S'
        // let time = parseInt(value.substring(1))
        axios({
          method: 'post',
          url: '/api/task/'+personId,
          data:{
            completed, taskId
          }
        } )
      },
      refresh(){
        this.renderId += 1
        this.person = {
          name:"",
          merits: {"entries":[]},
          lifetimeMerits: 0,
          image: "https://placekitten.com/200/200"
        }
        this.tasks = {}
        let personId = this.$route.params.id
        this.disabled = personId !== this.user?.username
        axios( '/api/person/'+personId).then((response)=>{
          console.log("Response: ",response)
          this.person = response.data.person
          this.tasks = response.data.tasks
          addDerived(this.person)
          addTaskDerived(this.tasks, response.data.completedTasks)
        })
      }
    }
  }
</script>