<template>
  
    <div class="m-3">
        <b-card title="Create Person">
            <b-form-input v-model="create.id" placeholder="Enter id"></b-form-input>
            <b-form-input v-model="create.name" placeholder="Enter name"></b-form-input>
            <b-form-input v-model="create.password" placeholder="Enter password"></b-form-input>
            <b-form-checkbox v-model="create.admin" :value="true" :unchecked-value="false">Administrator</b-form-checkbox>
            <b-button @click="createPerson">Create</b-button>
        </b-card>
        <b-card title="Manage Person" class="mt-3">
            <b-form-select v-model="selectedPerson" :options="people"></b-form-select>


            <b-input-group prepend="Name" class="mt-3" v-if="selectedPerson">
                <b-form-input v-model="edit.name" placeholder="Enter name" :disabled="!selectedPerson"></b-form-input>
                <b-input-group-append>
                    <b-button @click="updatePerson">Update</b-button>
                </b-input-group-append>
            </b-input-group>

            <b-input-group prepend="Password" class="mt-3" v-if="selectedPerson">
                <b-form-input v-model="edit.password" placeholder="Enter password" :disabled="!selectedPerson"></b-form-input>
                <b-input-group-append>
                    <b-button @click="updatePassword">Update</b-button>
                </b-input-group-append>
            </b-input-group>

            <b-card title="Merits" class="mt-3" v-if="selectedPerson">
                <div v-for="entry in edit?.merits?.entries" :key="entry.time">
                    {{ formatDate(entry.time) }}
                    <div v-for="v in entry.value" :key="v">
                        {{ v.number }} - {{ v.description }}
                    </div>
                </div>

                <b-input-group prepend="Merit" class="mt-3" v-if="selectedPerson">
                    <b-input-group-prepend>
                        <b-form-spinbutton id="merit-amount" v-model="meritAmount" min="-100" max="100" :disabled="!selectedPerson"></b-form-spinbutton>
                    </b-input-group-prepend>
                    <b-form-input aria-label="message" v-model="meritMessage" :disabled="!selectedPerson"></b-form-input>
                    <b-input-group-append>
                        <b-button @click="addMerit">Add</b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-card>

            <b-card title="Scheduled Tasks" class="mt-3" v-if="selectedPerson">

                <div v-for="entry in schedule.entries" :key="entry.id">
                    <b-input-group class="mt-3">
                        <!-- <b-input-group-prepend is-text>
                            <b-form-checkbox
                                :id="'checkbox-'+entry.id"
                                v-model="entry.category"
                                :name="'checkbox-'+entry.id"
                                value="work"
                                :unchecked-value="null"
                            />
                        </b-input-group-prepend> -->
                        <b-form-input v-model="entry.name" placeholder="Task name"></b-form-input>
                        <b-input-group-append>
                            <b-form-select v-model="entry.category" :options="categories"></b-form-select>
                        </b-input-group-append>
                        <b-input-group-append>
                            <b-form-select v-model="entry.duration" :options="durations"></b-form-select>
                        </b-input-group-append>
                        <b-input-group-append>
                            <b-form-select v-model="entry.dayOfWeek" :options="daysOfWeek"></b-form-select>
                        </b-input-group-append>
                        <b-input-group-append>
                            <b-form-select v-model="entry.hour" :options="hours"></b-form-select>
                        </b-input-group-append>
                        <b-input-group-append>
                            <b-form-select v-model="entry.minute" :options="minutes"></b-form-select>
                        </b-input-group-append>
                        <b-input-group-append>
                            <b-button @click="updateTask(entry)" variant="primary">Update</b-button>
                            <b-button @click="deleteTask(entry)" variant="danger">Delete</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </div>

                <b-input-group class="mt-3">
                    <b-form-input v-model="newTask.name" placeholder="Task name"></b-form-input>
                    <b-input-group-append>
                        <b-form-select v-model="newTask.category" :options="categories"></b-form-select>
                    </b-input-group-append>
                    <b-input-group-append>
                        <b-form-select v-model="newTask.duration" :options="durations"></b-form-select>
                    </b-input-group-append>
                    <b-input-group-append>
                        <b-form-select v-model="newTask.dayOfWeek" :options="daysOfWeek"></b-form-select>
                    </b-input-group-append>
                    <b-input-group-append>
                        <b-form-select v-model="newTask.hour" :options="hours"></b-form-select>
                    </b-input-group-append>
                    <b-input-group-append>
                        <b-form-select v-model="newTask.minute" :options="minutes"></b-form-select>
                    </b-input-group-append>
                    <b-input-group-append>
                        <b-button @click="addTask">Add</b-button>
                    </b-input-group-append>
                </b-input-group>

            </b-card>
            
        </b-card>
    </div>
</template>
<script>
import axios from "axios"

const HOURS = [];
const MINUTES = [];

for(let i=7;i<24;i++){
    HOURS.push({
      value: i,
      text: (i < 10 ? "0" : "") + i  
    })
}
for(let i=0;i<60;i++){
    MINUTES.push({
      value: i,
      text: (i < 10 ? "0" : "") + i  
    })
}

function formatDate(time){
  let date = new Date(time);
  return date.toLocaleDateString()
}

export default {
    data(){
        return {
            create:{
                id: "",
                name: ""
            },
            people:[],
            selectedPerson: undefined,
            edit:{
                name:""
            },
            meritAmount: 1,
            meritMessage: "",
            schedule: {entries:[]},
            daysOfWeek:[
                {value: 2, text: "Monday"},
                {value: 3, text: "Tuesday"},
                {value: 4, text: "Wednesday"},
                {value: 5, text: "Thursday"},
                {value: 6, text: "Friday"},
                {value: 7, text: "Saturday"},
                {value: 1, text: "Sunday"},
                {value: -1, text: "Every day"},
                {value: -2, text: "Week day"},
                {value: -3, text: "Weekend day"}
            ],
            durations:[
                {value:5, text: "5 mins"},
                {value:10, text: "10 mins"},
                {value:20, text: "20 mins"},
                {value:30, text: "30 mins"},
                {value:60, text: "1 hour"},
                {value:90, text: "1.5 hours"},
                {value:120, text: "2 hours"},
                {value:180, text: "3 hours"},
                {value:240, text: "4 hours"},
                {value:300, text: "5 hours"},
                {value:360, text: "6 hours"},
                {value:420, text: "7 hours"},
                {value:480, text: "8 hours"}
            ],
            categories:[
                {value:"housework",text:"Housework"},
                {value:"work",text:"Work/School"},
                {value:"exercise",text:"Exercise"}
            ],
            hours: HOURS,
            minutes: MINUTES,
            newTask:{
                name: undefined,
                dayOfWeek: undefined,
                time: undefined,
                hour: 8,
                minute: 0,
                category: undefined
            }
        }
    },
    mounted(){
        this.refresh()
    },
    watch:{
        selectedPerson(){
            this.refreshEdit()
        }
    },
    methods:{
        createPerson(){
            axios({
                method: 'post',
                url: '/api/person/'+this.create.id,
                data: {
                    name: this.create.name,
                    password: this.create.password,
                    admin: this.create.admin
                }
            }).then(()=>{
                this.refresh()
            })
        },
        updatePerson(){
            axios({
                method: 'post',
                url: '/api/person/'+this.selectedPerson,
                data: {
                    name: this.edit.name
                }
            }).then(()=>{
                this.refresh()
            })
        },
        updatePassword(){
            axios({
                method: 'post',
                url: '/api/person/'+this.selectedPerson+"/password",
                data: {
                    password: this.edit.password
                }
            }).then(()=>{
                this.refresh()
            })
        },
        addMerit(){
            axios({
                method: 'post',
                url: '/api/add-merit/'+this.selectedPerson+"/"+this.meritAmount,
                data: {
                    msg: this.meritMessage
                }
            }).then(()=>{
                this.refreshEdit()
            })
        },
        addTask(){
            let name = this.newTask.name
            let dayOfWeek = this.newTask.dayOfWeek
            let hour = this.newTask.hour
            let minute = this.newTask.minute
            let duration = this.newTask.duration
            let category = this.newTask.category
            
            axios({
                method: 'post',
                url: '/api/add-task/'+this.selectedPerson,
                data: {
                    name, dayOfWeek, hour, minute, duration, category
                }
            }).then(()=>{
                this.refreshEdit()
            })
        },
        updateTask(entry){
            let name = entry.name
            let dayOfWeek = entry.dayOfWeek
            let hour = entry.hour
            let minute = entry.minute
            let duration = entry.duration
            let category = entry.category
            axios({
                method: 'post',
                url: '/api/update-task/'+this.selectedPerson+"/"+entry.id,
                data: {
                    name, dayOfWeek, hour, minute, duration, category
                }
            }).then(()=>{
                this.refreshEdit()
            })

        },
        deleteTask(entry){
            axios({
                method: 'post',
                url: '/api/delete-task/'+this.selectedPerson+"/"+entry.id,
            }).then(()=>{
                this.refreshEdit()
            })
        },
        refresh(){
            this.edit.password = undefined
            axios("/api/people").then((response)=>{
                this.people = response.data
            })
        },
        refreshEdit(){
            if(this.selectedPerson){
                axios( '/api/person/'+this.selectedPerson).then((response)=>{
                    this.edit = response.data.person
                })
            }else{
                this.edit = {name:""}
            }
            axios("/api/schedule/"+this.selectedPerson).then((response)=>{
                this.schedule = response.data
            })
        },
        formatDate
    }
}
</script>