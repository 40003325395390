import Vue from 'vue'
import Router from 'vue-router';
import HomeView from "../views/HomeView.vue";
import AdminView from "../views/AdminView.vue";
import PersonView from "../views/PersonView.vue";
import ReportView from "../views/ReportView.vue";
import ExpectationsView from "../views/ExpectationsView.vue";

Vue.use(Router);

export default new Router({
    routes: [
        {
          path: "/",
          name: "home",
          component: HomeView,
        },
        {
          path: "/admin",
          name: "admin",
          component: AdminView,
        },
        {
          path: "/person/:id",
          name: "person",
          component: PersonView,
        },
        {
          path: "/report",
          name: "report",
          component: ReportView,
        },
        {
          path: "/expectations",
          name: "expectations",
          component: ExpectationsView,
        }
    ]
})